<template>
  <!-- 客户服务 -->
  <div class="">
    <head-nav></head-nav>
    <!--  头部导航   -->
    <div>
      <header class="index1">
        <div class="backgroundImg">
          <!-- <head-nav></head-nav> -->
          <!-- 背景图 -->
          <div class="backgroundImgAll" style="">
            <div class="backgroundImg1">客户服务</div>
            <div class="backgroundImg2">CUSTOMER SERVICE</div>
          </div>
        </div>
      </header>
    </div>

    <!-- 全方位服务 -->
    <div class="systemTools" style="">
      <!--  -->
      <div class="systemTools-1">
        <div class="systemTools1">
          <div class="systemTools1-1">
            <div class="systemTools1-1-1"></div>
            <div class="systemTools1-1-2">360°全方位服务</div>
            <div class="systemTools1-1-3"></div>
          </div>
          <div class="systemTools1-2"></div>
        </div>
      </div>

      <!--  -->
      <div
        class="systemTools-1 flex jc-space"
        style="margin-top: 60px; margin-bottom: 60px"
      >
        <div class="service">
          <div class="serviceImg1"></div>
          <div class="service1" style="width: 182px">1：1专属技术支持</div>
          <div class="service2">
            企讯客为每位客户安排了1对1的专业技术支持，全天24小时服务响应
          </div>
        </div>
        <div class="service">
          <div class="serviceImg2"></div>
          <div class="service1" style="width: 130px">90S服务响应</div>
          <div class="service2">
            对于客户提出的各种问题，一般情况下技术均在90S内做出响应，大大的提高了工作效率，也降低了故障率
          </div>
        </div>
        <div class="service">
          <div class="serviceImg3"></div>
          <div class="service1" style="width: 134px">产品使用培训</div>
          <div class="service2">
            指派专业人员进行产品的现场使用培训或在线远程培训，保障每一位客户都能正常使用，系统简单上手快
          </div>
        </div>
        <div class="service">
          <div class="serviceImg4"></div>
          <div class="service1" style="width: 134px">系统实时升级</div>
          <div class="service2">
            企讯客一直在研发各种更实用的功能，公司会不定期进行升级与维护，为广大客户提供更大的便利
          </div>
        </div>
      </div>
    </div>

    <!-- 服务流程 -->
    <div class="systemTools" style="">
      <!--  -->
      <div class="systemTools-1">
        <div class="systemTools1">
          <div class="systemTools1-1">
            <div class="systemTools1-1-1"></div>
            <div class="systemTools1-1-2">服务流程</div>
            <div class="systemTools1-1-3"></div>
          </div>
          <div class="systemTools1-2"></div>
        </div>
      </div>

      <!--  -->
      <div class="systemTools-1" style="margin-top: 60px; margin-bottom: 60px">
        <div class="procedure">
          <div class="procedure1">
            <div class="procedure1-1" style="margin: 41px auto 30px">
              <div class="procedureImg1"></div>
              <div class="procedure1-1-1" style="margin-left: 10px">沟通</div>
            </div>
            <div class="procedure1-2">
              确定项目对接人， 组建微信运营群，提供专业咨询服务
            </div>
          </div>
          <div class="procedure1">
            <div class="procedure1-1" style="margin: 41px auto 30px">
              <div class="procedureImg2"></div>
              <div class="procedure1-1-1" style="margin-left: 10px">配置</div>
            </div>
            <div class="procedure1-2">
              根据业务需求， 完成系统初始化，并进行配置优调
            </div>
          </div>
          <div class="procedure1">
            <div class="procedure1-1" style="margin: 40px auto 30px">
              <div class="procedureImg3"></div>
              <div class="procedure1-1-1" style="margin-left: 10px">培训</div>
            </div>
            <div class="procedure1-2">
              针对客户不同角色 和部门区分应用场景，分别进行培训
            </div>
          </div>
        </div>
        <div class="procedure" style="margin-top: 30px; margin-bottom: 131px">
          <div class="procedure1">
            <div class="procedure1-1" style="margin: 40px auto 30px">
              <div class="procedureImg4"></div>
              <div class="procedure1-1-1" style="margin-left: 10px">迁移</div>
            </div>
            <div class="procedure1-2">
              提供数据标准， 进行数据格式加工，并进行数据迁移
            </div>
          </div>
          <div class="procedure1">
            <div class="procedure1-1" style="margin: 40px auto 30px">
              <div class="procedureImg5"></div>
              <div class="procedure1-1-1" style="margin-left: 10px">辅助</div>
            </div>
            <div class="procedure1-2">
              辅助系统上线， 专业指导确保系统正常操作运行
            </div>
          </div>
          <div class="procedure1">
            <div class="procedure1-1" style="margin: 40px auto 30px">
              <div class="procedureImg6"></div>
              <div class="procedure1-1-1" style="margin-left: 10px">反馈</div>
            </div>
            <div class="procedure1-2">
              收集用户使用反馈， 更新培训，并输出解决方案
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import HeadNav from "@/components/headNav.vue";
import Footer from "@/components/footer.vue";
export default {
  name: "",
  components: {
    HeadNav,
    Footer,
  },
  data() {
    return {};
  },
  mounted() {
    // this.timeChange();
  },
  beforeDestroy() {
    // clearInterval(this.timer);
  },
  methods: {},
};
</script>

<style src="@/style/CustomerService.css" scoped></style>
<style lang="scss" scoped></style>
